
<template>
<div class="clue-list-index">
            <navigationBar/>
        <div >
            <!--列表-->
            <el-table :data="dataEntityList" border highlight-current-row   v-loading="listLoading" style="width: 100%;margin-top: 1.5rem;" >
                <el-table-column property="idNo" label="序号" width="60px"></el-table-column>
                <el-table-column property="name" label="名称"    ></el-table-column>
              
                <el-table-column label="操作" width="150px">
                    <template slot-scope="scope">
                      <el-button  size="mini"  >查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="Pagination" style="text-align: right;margin-top: 10px;">
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="pageNo"
                        :page-size="pageSize"
                        layout="total, prev, pager, next"
                        :total="this.count">
                </el-pagination>
            </div>
        </div>
    </div>
      
</template>

<script>
import {getTestListInfoPage } from '@/api/getBaseData'
import cookies from '@/utils/cookies/index.js'
import navigationBar from '@/components/navigationBar'
export default {
  data (){
    return {
      filters:{
        name:"",
      },

      lastPage:'',
      currentRow: null,
      offset: 0,
      pageSize: 3,
      count: 0,
      pageNo: 0,
      listLoading: false,
      dataEntityList:[],
      user:{},
     
    }
  },
 
  activated(){
     let uid = cookies.get("uid");  
     this.user = JSON.parse(localStorage.getItem('userInfo'+ uid))
  },
  mounted(){
     this.doQuery();
  },
  methods:{
 
      handleCurrentChange:function(pageNo){
              this.pageNo = pageNo;
              this.doQuery();
      },
      doQuery(){
        let para ={
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          name:this.filters.name,
        };
        this.listLoading = true;
        getTestListInfoPage(para).then((res)=>{
          this.count = res.total;
          this.lastPage = res.pages
          this.dataEntityList = res.list;
          
          this.listLoading = false;
        },(err)=>{
          this.$message({
            message: '数据请求失败',
            type: 'error'
          });
          this.listLoading = false;
        });
      },
 

      resetForm(formName) {
        this.filters={}
        this.doQuery();           
      },
 

  }}
  </script>

<style lang="scss">
.clue-list-index{
      
 
}
</style>
